import React from 'react';
import { useState, useRef, useEffect }  from 'react';
import './App.css';
import Colors from './colors.json'

const functionURL = 'https://okg2cp3qvram2opnt4mhfhubx40rspmt.lambda-url.eu-central-1.on.aws/'

type Message = {
  user: 'airon' | 'human',
  text: string,
}

// type target = {}

function App() {

  const formRef = useRef(document.createElement("form"));
  const inputRef = useRef(document.createElement("textarea"));
  const loadingRef = useRef(document.createElement("p"));
  const convoRef =  useRef(document.createElement("div"));
  const mainRef =  useRef(document.createElement("main"));
  const asideRef =  useRef(document.createElement("aside"));

  const [readerMode, setReaderMode] = useState('color')
  const [fontSize, setFontSize] = useState('regular')
  const [aironID, setAironID] = useState('Airon')
  const [humanID, setHumanID] = useState('Human')
  const [openAside, setOpenAside] = useState('hide')
  const [colors, setColors] = useState(0)
  const [inputValue, setInputValue ] = useState('')
  const [messages, setMessages] = useState<Message[]>([])
  // const [firstMessage, setFirstMessage] = useState(`loaded <b>v5.2</b> of AIron. Color mode: <b>` + readerMode + `</b>. Font size: <b>` + fontSize + `</b>. Initial color combination <b>` + colors.toString().padStart(3, '0') + `</b> retrieved from <a href="https://www.goodreads.com/book/show/19976800-a-dictionary-of-color-combinations" target="_blank">Sanzo Wada's A Dictionary of Color Combinations</a>`)


const openExplanation = (newValue: string) => {
  setOpenAside(newValue)
  // openAside === 'show' ? setOpenAside('hide') : setOpenAside('show')
}

const pickColors = (colorChoice: number) => {

  const sanzo = Colors
  const colorCombinations = sanzo.colors.map((color: any) => [color.index, color.hex, color.combinations])
  const correctCombo =  colorCombinations.filter((color: any) => color[2].includes(colorChoice))
  console.log(correctCombo) 

    if (correctCombo[1] !== undefined) {
      const colorOneHex = correctCombo[0][1]
      const colorTwoHex = correctCombo[1][1]    

      // console.log(mainRef, colorOneHex, colorTwoHex)
      // mainRef.current.style.background = colorOneHex
      // mainRef.current.style.color = colorTwoHex

      mainRef.current.style.color = colorOneHex
      mainRef.current.style.background = colorTwoHex
      // p span input input::placholder

      // document.querySelector('body').style.background = colorOneHex
      // document.querySelector('section').style.color = colorTwoHex

      // document.querySelector('aside .color-combo').innerHTML = "color combination <b>no. " + combination.toString().padStart(3, '0') + "</b> <em>stolen</em> from Sanzo Wada's <i>A Dictionary of Color Combinations </i>"
      setReaderMode('color')
    } else {
      const reroll = Math.floor(Math.random() * 120) + 1
      pickColors(reroll)  
    }
}


const displayMessage = (target: any, message: string, messageHTML: string, index: number) => {
  if (index < message.length) {

    let interval = 50
    
    const pause = ['.', ',', '']
    if (pause.includes(message[index])) {
      interval = interval * 3
    }

    target.append(message[index++]);
    setTimeout(function () { displayMessage(target, message, messageHTML, index); });
  }
}

  const newMessage: React.FormEventHandler = async (e) => {
    e.preventDefault();
    setInputValue('')
    
    loadingRef.current.style.display = 'block'
    formRef.current.style.visibility = 'hidden'

    const newMessages: Message[] = [...messages, {
      user: 'human',
      text: inputValue
    }]
    setMessages(newMessages);
    const response = await fetch (functionURL, {
      method: 'POST',
      body: JSON.stringify({messages: newMessages})
    });

    const responseText = await response.text()
    
    loadingRef.current.style.display = 'none'

    setMessages([...newMessages, {
      user: 'airon',
      text: responseText
    }]);


      if (responseText.includes('Error 00')) {
        setTimeout(() => {
          window.location.reload(); 
        }, 5000);
      } else if (responseText.includes('Lucy')) {
        (document.querySelector('#layerNeko0') as HTMLInputElement).style.display = 'block'
      } else if (responseText.includes('Activating color combination')) {
        const colorNumber = parseInt(responseText.replace(/^\D+/g, ''));
        console.log(responseText, responseText.replace(/\D/g, ''), colorNumber)
        pickColors(colorNumber)
      } else if (responseText.includes('Activating color mode:') || responseText.includes('Activating font mode:')) {
        
        const regex = /<b>(.*?)<\/b>/;
        const mode = responseText.match(regex)
        if (mode) {
          responseText.includes('Activating color mode:') ? setReaderMode(mode[1]) : setFontSize(mode[1]);
        }
      }else if (responseText.includes('User <b>') && responseText.includes('</b> recognised')) {
        const newHuman = responseText.replace(/.*<b>(.*?)<\/b>.*/, '$1')
        const newHumanShort = newHuman.substring(0, 5)
        setHumanID(newHumanShort)
      } else if (responseText.includes('girlfriend') || responseText.includes('relationship')) {
        setAironID('A❣ron')
      }

      // display text -> displayMessage(newText, text, html, 0)  

      formRef.current.style.visibility = 'visible'
      inputRef.current.focus()

      console.log(mainRef.current.scrollHeight, mainRef.current.offsetHeight) 

  }

  const inputTextChange = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
    let target = e.target as HTMLTextAreaElement;

    const enterRegex = /(<br\s*\/?>|\n|\r\n|\r)/
    
    setInputValue(target.value)

    if (enterRegex.test(target.value)) {
      formRef.current.requestSubmit()
    }

  }

  const createMarkup = (text: string) => {
      return {__html: text};
  }
  
// pick initial colors
  useEffect(() => {
    const startColor = Math.floor(Math.random() * 120) + 1
    // const initialMessage = {
    //   user: 'airon',
    //   text: `<span>loaded <b>v5.2</b> of AIron. Initial mode: <b>color</b>. initial font size: <b>regular</b>. initial color combination <b>` + colors.toString().padStart(3, '0') + `</b> retrieved from <a href="https://www.goodreads.com/book/show/19976800-a-dictionary-of-color-combinations" target="_blank">Sanzo Wada's A Dictionary of Color Combinations</a></span>`
    // }

    setColors(startColor)
    pickColors(startColor)

    const initialMessage: Message[] = [{
      user: 'airon',
      text: `loaded <b>v5.2</b> of AIron. Color mode: <b>` + readerMode + `</b>. Font size: <b>` + fontSize + `</b>. Initial color combination <b>` + startColor.toString().padStart(3, '0') + `</b> retrieved from <a href="https://www.goodreads.com/book/show/19976800-a-dictionary-of-color-combinations" target="_blank">Sanzo Wada's A Dictionary of Color Combinations</a>`   
    }]
    // console.log(colors)
    setMessages(initialMessage)  
 }, []);
 
//  close aside when clicked outside
 useEffect(() => {

  // const reloadInitialMessage = () => {
  //   const newInitialMessage: Message[] = [{
  //     user: 'airon',
  //     text: `loaded <b>v5.2</b> of AIron. Color mode: <b>` + readerMode + `</b>. Font size: <b>` + fontSize + `</b>. Initial color combination <b>` + colors.toString().padStart(3, '0') + `</b> retrieved from <a href="https://www.goodreads.com/book/show/19976800-a-dictionary-of-color-combinations" target="_blank">Sanzo Wada's A Dictionary of Color Combinations</a>`   
  //   }]
  //   setMessages(newInitialMessage.concat(messages.slice(1, -0)));
  //   console.log(readerMode)
  // }

  const handleClickOutside = (event: any) => {
    if (asideRef.current && !asideRef.current.contains(event.target)) {
      openExplanation('hide')
    }
    // reloadInitialMessage()
  }

  document.addEventListener("mousedown", handleClickOutside);
  document.addEventListener("touchstart", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
    document.removeEventListener("touchstart", handleClickOutside);
  };
}, [asideRef]);

useEffect(() => {
  if (mainRef.current.scrollHeight !== mainRef.current.offsetHeight){
    mainRef.current.scrollBy(0, 1000)
  } 
});



    return (
      <main ref={mainRef} className={readerMode + " " + fontSize}>
        <section>
          <div className="conversation" ref={convoRef}>
            {/* <p className='airon initial'><span className='airon'>{aironID + ':'}</span><span>loaded <b>v5.2</b> of AIron. Color combination <b>{colors.toString().padStart(3, '0')}</b> retrieved from <a href="https://www.goodreads.com/book/show/19976800-a-dictionary-of-color-combinations" target="_blank">Sanzo Wada's A Dictionary of Color Combinations</a></span></p> */}
            {messages.map((message, index) => 
              <p key={index} className={message.user == 'human' ? 'human prompt' : message.text.includes('Error 00') ? 'airon error' : 'airon reply'}>
                {message.user == 'human' ? <span className='human'>{humanID + ':'}</span> : <span className='airon'>{aironID + ':'}</span>}
                {message.user === 'human' ? <span>{message.text}</span> : <span dangerouslySetInnerHTML={createMarkup(message.text)} />}
              </p>
            )}
            <p className="loading dotdotdot" ref={loadingRef}>loading</p>
          </div>
          
          <form onSubmit={newMessage} ref={formRef}>
            <div>
            <p><span className='human'>{humanID + ':'}</span></p>
            <textarea 
            ref={inputRef}
            autoFocus
            placeholder="ask"
            value={inputValue} 
            maxLength={60}
            onChange= {inputTextChange}
            >
            </textarea>
            </div>
            <input type="submit" value="Submit"></input>
          </form>
        </section>
        <div className="open" onClick={() => openExplanation('show')}><p>???</p></div>
        <aside className={openAside} ref={asideRef}>
          <p className="title">explanation & settings <span onClick={() => openExplanation('hide')}>x</span></p>
          <ul>
            <li>This is AIron, AIron can do a few things and knows a quite bit about Aron. </li>
            <li>If you'd like to know more about Aron, you can ask AIron anything.</li>
            <li><i>Examples:</i> AIron knows how Aron makes money, how he spends most of his free time and random things like what he likes to eat for breakfast.</li>
            <li>Do you think the font is too small? You can enlarge it by clicking on <b onClick={() => setFontSize('big')}>big</b> and switch back by clicking on <b onClick={() => setFontSize('regular')}>regular</b>.</li>
            <li>If you don't like the color scheme you can also ask AIron to change the color scheme or click on <b onClick={() => setReaderMode('light')}>light</b> or <b onClick={() => setReaderMode('dark')}>dark</b> mode for easier reading.</li>
            <li>Asking AIron to pick a color scheme will automatically change the color setting to <b onClick={() => setReaderMode('color')}>color</b>.</li>
            <li>You can also ask AIron to change these settings for you</li>
            <li>Please note: <i>these settings are still in beta, AIron may get confused.</i></li>
            <li>Reloading the page will pick a different (random) color scheme and restart the conversation.</li>
            <li>Conversations are not recorded or stored anywhere so if you notice a bug or it seems like AIron's reply wasn't very <i>"Aron-like"</i> please let Aron know personally.</li>
            <li className="dotdotdot">AIron has some hidden features that can be discovered if you ask the right questions</li>
          </ul>
          <div className="readability">
            <p className="theme">
              color mode:
              <span className={readerMode === 'color' ? "active" : ""} onClick={() => setReaderMode('color')}>color</span>
              <span className={readerMode === 'light' ? "active" : ""} onClick={() => setReaderMode('light')}>light</span>
              <span className={readerMode === 'dark' ? "active" : ""} onClick={() => setReaderMode('dark')}>dark</span>
            </p>
            {/* <p className="color-mode" >
            shuffle random color combo:<span onClick={() => {const reroll = Math.floor(Math.random() * 120) + 1; pickColors(reroll)}}><b>({colors})</b></span>    
            </p> */}
            <p className="font">
              font size:
              <span className={fontSize === 'regular' ? "active" : ""} onClick={() => setFontSize('regular')}>regular</span>
              <span className={fontSize === 'big' ? "active" : ""} onClick={() => setFontSize('big')}>big</span>          
            </p>
          </div>
        </aside>
      </main>
    );
}

export default App;
